/* eslint-env browser */
/*
 * Headroom.js, including jQuery support, for ID7 fixed header
 *
 * http://wicky.nillia.ms/headroom.js
 */
import Headroom from 'headroom.js';

window.Headroom = Headroom;
require('headroom.js/dist/jQuery.headroom');
