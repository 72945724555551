/* eslint-env browser */
/*
 * Easy toggling of collapsing elements.
 *
 * https://getbootstrap.com/docs/3.4/javascript/#collapse
 */

// Required dependency
require('./bootstrap-transition.jquery');
require('../bootstrap/js/collapse');
